<template>
  <div class="container">
    <!-- 聊天窗口 -->
    <div class="chat-botWrap">
      <div class="chat-wrapper">
        <el-row>
          <el-col :xs="10" :sm="10" :md="8" :lg="8" :xl="7">
            <side-bar type="wukong" />
          </el-col>
          <el-col :xs="14" :sm="14" :md="16" :lg="16" :xl="17">
            <current-conversation showHeaderConversationDown="true" showAllGroupName="true" />
          </el-col>
          <el-col :xs="14" :sm="14" :md="16" :lg="16" :xl="17"> </el-col>
        </el-row>
      </div>
      <div class="student-infoWrap">
        <student-info></student-info>
      </div>
    </div>
    <calling ref="callLayer" class="chat-wrapper" />
    <image-previewer />
    <group-live />

    <div class="bg"></div>
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import { mapState } from 'vuex';
import SideBar from '../../components/IM/layout/side-bar';
import CurrentConversation from '../../components/IM/conversation/current-conversation';
import ImagePreviewer from '../../components/IM/message/image-previewer.vue';
import Calling from '../../components/IM/message/trtc-calling/calling-index';
import GroupLive from '../../components/IM/group-live/index';
import StudentInfo from '@/views/im/studentInfo.vue';
import { handlestopReceivingOrders, getStudentsInfo } from '@/api/xiaowukong';
import { getArrEqualXWK, getArrIsSameXWK } from '@/utils/util';
import { REMEMBERME } from '@/store/mutation-types';

import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
const session = storage.createStore(sessionStorage);

export default {
  name: 'Xiaowukong',
  data() {
    return {
      // 会话一更新 sdk列表也会更新
      sdkStudentList: [],
      // 学生接口数据
      studentsInfo: [],
    };
  },
  components: {
    SideBar,
    CurrentConversation,
    ImagePreviewer,
    Calling,
    GroupLive,
    StudentInfo,
  },

  computed: {
    ...mapState({
      studentName: (state) => state.conversation.studentName,
      currentUserProfile: (state) => state.userIM.currentUserProfile,
      currentConversation: (state) => state.conversation.currentConversation,
      videoCall: (state) => state.conversation.videoCall,
      audioCall: (state) => state.conversation.audioCall,
      isLogin: (state) => state.user.isLogin,
      isSDKReady: (state) => state.userIM.isSDKReady,
      isBusy: (state) => state.video.isBusy,
      userID: (state) => state.userIM.userID,
      userSig: (state) => state.userIM.userSig,
      sdkAppID: (state) => state.userIM.sdkAppID,
      searchStudentName: (state) => state.conversation.studentName,
    }),
    // 是否显示 Loading 状态
    showLoading() {
      return !this.isSDKReady;
    },
  },

  mounted() {
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e));
    window.addEventListener('unload', (e) => this.unloadHandler(e));
    // 学生信息筛选
    this.$bus.$on('handleStudentInfoSearch', this.handleStudentInfoSearch);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadHandler(e));
    window.removeEventListener('unload', (e) => this.unloadHandler(e));
    this.$bus.$off('handleStudentInfoSearch', this.handleStudentInfoSearch);
  },

  watch: {
    isLogin(next) {
      if (next) {
        MTA.clickStat('link_two', { show: 'true' });
      }
    },
    // userID:function(){
    //     this.handleGetStudentInfo()
    // }
  },

  methods: {
    initListener() {
      //刷新浏览器,让IM不断开
      // const obj = storage.get(REMEMBERME) ? storage.get('userObj') : session.get('userObj');
      const obj = storage.get('userObj');
      if (obj) {
        this.tim
          .login({
            userID: obj.userID,
            userSig: obj.userSig,
          })
          .then(() => {
            console.log('IM登录成功--》');
            this.$store.commit({
              type: 'GET_USER_INFO',
              userID: obj.userID,
              userSig: obj.userSig,
              sdkAppID: obj.sdkAppID,
            });
          })
          .catch((error) => {
            console.log('IM登录失败--》');
            this.loading = false;
            this.$store.commit('showMessage', {
              message: '登录失败：' + error.message,
              type: 'error',
            });
          });
      }
    },
    //初始化IM的监听事件
    allMonitoring() {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.on(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this);
      // SDK NOT READT
      this.tim.on(this.TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this);
      // 被踢出
      this.tim.on(this.TIM.EVENT.KICKED_OUT, this.onKickOut);
      // SDK内部出错
      this.tim.on(this.TIM.EVENT.ERROR, this.onError);
      // 收到新消息
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
      // 会话列表更新
      this.tim.on(this.TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onUpdateConversationList);
      // 群组列表更新 每次有新会话也会触发
      //  this.tim.on(this.TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList)
      // 网络监测
      this.tim.on(this.TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange);
      // 已读回执
      this.tim.on(this.TIM.EVENT.MESSAGE_READ_BY_PEER, this.onMessageReadByPeer);
    },
    onReceiveMessage({ data: messageList }) {
      this.handleVideoMessage(messageList);
      this.handleAt(messageList);
      this.handleQuitGroupTip(messageList);
      this.handleCloseGroupLive(messageList);
      this.$store.commit('pushCurrentMessageList', messageList);
      this.$store.commit('pushAvChatRoomMessageList', messageList);
    },
    onError({ data }) {
      if (data.message !== 'Network Error') {
        this.$store.commit('showMessage', {
          message: data.message,
          type: 'error',
        });
      }
    },
    onMessageReadByPeer() {},
    onReadyStateUpdate({ name }) {
      const isSDKReady = name === this.TIM.EVENT.SDK_READY ? true : false;
      this.$store.commit('toggleIsSDKReady', isSDKReady);

      if (isSDKReady) {
        this.tim
          .getMyProfile()
          .then(({ data }) => {
            this.$store.commit('updateCurrentUserProfile', data);
          })
          .catch((error) => {
            this.$store.commit('showMessage', {
              type: 'error',
              message: error.message,
            });
          });
        this.$store.dispatch('getBlacklist');
        // 登录trtc calling
        this.trtcCalling.login({
          sdkAppID: this.sdkAppID,
          userID: this.userID,
          userSig: this.userSig,
        });
      }
    },
    kickedOutReason(type) {
      switch (type) {
        case this.TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:
          return '当前账号在其他网页登录，此网页已退出登录';
        case this.TIM.TYPES.KICKED_OUT_MULT_DEVICE:
          return '由于多设备登录，被踢出，请重新登录';
        case this.TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED:
          return '由于 userSig 过期，被踢出，请重新登录';
        default:
          return '';
      }
    },
    checkoutNetState(state) {
      switch (state) {
        // case this.TIM.TYPES.NET_STATE_CONNECTED:
        //   return { message: '已接入网络', type: 'success' };
        case this.TIM.TYPES.NET_STATE_CONNECTING:
          return { message: '当前网络不稳定', type: 'warning' };
        case this.TIM.TYPES.NET_STATE_DISCONNECTED:
          return { message: '当前网络不可用', type: 'error' };
        default:
          return '';
      }
    },
    onNetStateChange(event) {
   if(event.data.state===this.TIM.TYPES.NET_STATE_CONNECTED){
      return false
    }
      this.$store.commit('showMessage', this.checkoutNetState(event.data.state));
    },
    onKickOut(event) {
      this.$store.commit('reset');
      //清除登录的信息，免得刷新浏览器又自动登录
      session.remove('userObj');
      storage.remove('userObj');
      this.$confirm(`${this.kickedOutReason(event.data.type)}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        showClose: false,
      })
        .then(() => {
          this.$store.dispatch('Logout').then(() => {
            this.$store.commit('SET_ROUTERS', []);
            const url = window.location.host + this.$router.options.base + this.$router.history.current.path;
            window.location.href = url;
          });
        })
        .catch(() => {});
    },

    onUpdateConversationList(event) {
      if (!this.userID) {
        return;
      }

      const newStudentList = event.data.filter((item) => {
        return item.type === 'GROUP';
      });
      console.log(newStudentList);
      session.set('sdkStudentList', newStudentList);
      this.$store.commit('updateConversationList', newStudentList);
    },
    onUpdateGroupList(event) {
      // console.log('群组列表更新',event.data)
      // this.$store.commit('updateGroupList', event.data)
    },
    onReceiveGroupSystemNotice(event) {
      const isKickedout = event.data.type === 4;
      const isCurrentConversation =
        `GROUP${event.data.message.payload.groupProfile.groupID}` === this.currentConversation.conversationID;
      // 在当前会话被踢，需reset当前会话
      if (isKickedout && isCurrentConversation) {
        this.$store.commit('resetCurrentConversation');
      }
      Notification({
        title: '新系统通知',
        message: translateGroupSystemNotice(event.data.message),
        duration: 3000,
        onClick: () => {
          const SystemConversationID = '@TIM#SYSTEM';
          this.$store.dispatch('checkoutConversation', SystemConversationID);
        },
      });
    },
    /**
     * 处理 @ 我的消息
     * @param {Message[]} messageList
     */
    handleAt(messageList) {
      // 筛选有 @ 符号的文本消息
      const atTextMessageList = messageList.filter(
        (message) => message.type === this.TIM.TYPES.MSG_TEXT && message.payload.text.includes('@'),
      );
      for (let i = 0; i < atTextMessageList.length; i++) {
        const message = atTextMessageList[i];
        const matched = message.payload.text.match(/@\w+/g);
        if (!matched) {
          continue;
        }
        // @ 我的
        if (matched.includes(`@${this.currentUserProfile.userID}`)) {
          // 当前页面不可见时，调用window.Notification接口，系统级别通知。
          if (this.$store.getters.hidden) {
            this.notifyMe(message);
          }
          Notification({
            title: `有人在群${message.conversationID.slice(5)}提到了你`,
            message: message.payload.text,
            duration: 3000,
          });
          this.$bus.$emit('new-messsage-at-me', {
            data: { conversationID: message.conversationID },
          });
        }
      }
    },
    selectConversation(conversationID) {
      if (conversationID !== this.currentConversation.conversationID) {
        this.$store.dispatch('checkoutConversation', conversationID);
      }
    },
    isJsonStr(str) {
      try {
        JSON.parse(str);
        return true;
      } catch {
        return false;
      }
    },
    handleVideoMessage(messageList) {
      const videoMessageList = messageList.filter(
        (message) => message.type === this.TIM.TYPES.MSG_CUSTOM && this.isJsonStr(message.payload.data),
      );
      if (videoMessageList.length === 0) return;
      const videoPayload = JSON.parse(videoMessageList[0].payload.data);
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_DIALING) {
        if (this.isBusy) {
          this.$bus.$emit('busy', videoPayload, videoMessageList[0]);
          return;
        }
        this.$store.commit('GENERATE_VIDEO_ROOM', videoPayload.room_id);
        this.selectConversation(videoMessageList[0].conversationID); // 切换当前会话页
        if (videoMessageList[0].from !== this.userID) {
          this.$bus.$emit('isCalled');
        }
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_SPONSOR_CANCEL) {
        this.$bus.$emit('missCall');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_REJECT) {
        this.$bus.$emit('isRefused');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_SPONSOR_TIMEOUT) {
        this.$bus.$emit('missCall');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_ACCEPTED) {
        this.$bus.$emit('isAccept');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_HANGUP) {
        this.$bus.$emit('isHungUp');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_LINE_BUSY) {
        this.$bus.$emit('isRefused');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_ERROR) {
        this.$bus.$emit('isRefused');
      }
    },
    /**
     * 使用 window.Notification 进行全局的系统通知
     * @param {Message} message
     */
    notifyMe(message) {
      // 需检测浏览器支持和用户授权
      if (!('Notification' in window)) {
        return;
      } else if (window.Notification.permission === 'granted') {
        this.handleNotify(message);
      } else if (window.Notification.permission !== 'denied') {
        window.Notification.requestPermission().then((permission) => {
          // 如果用户同意，就可以向他们发送通知
          if (permission === 'granted') {
            this.handleNotify(message);
          }
        });
      }
    },
    handleNotify(message) {
      const notification = new window.Notification('有人提到了你', {
        icon: 'https://webim-1252463788.file.myqcloud.com/demo/img/logo.dc3be0d4.png',
        body: message.payload.text,
      });
      notification.onclick = () => {
        window.focus();
        this.$store.dispatch('checkoutConversation', message.conversationID);
        notification.close();
      };
    },
    /**
     * 收到有群成员退群/被踢出的groupTip时，需要将相关群成员从当前会话的群成员列表中移除
     * @param {Message[]} messageList
     */
    handleQuitGroupTip(messageList) {
      // 筛选出当前会话的退群/被踢群的 groupTip
      const groupTips = messageList.filter((message) => {
        return (
          this.currentConversation.conversationID === message.conversationID &&
          message.type === this.TIM.TYPES.MSG_GRP_TIP &&
          (message.payload.operationType === this.TIM.TYPES.GRP_TIP_MBR_QUIT ||
            message.payload.operationType === this.TIM.TYPES.GRP_TIP_MBR_KICKED_OUT)
        );
      });
      // 清理当前会话的群成员列表
      if (groupTips.length > 0) {
        groupTips.forEach((groupTip) => {
          if (Array.isArray(groupTip.payload.userIDList) || groupTip.payload.userIDList.length > 0) {
            this.$store.commit('deleteGroupMemberList', groupTip.payload.userIDList);
          }
        });
      }
    },
    /**
     * 收到结束直播自定义消息，派发事件关闭组件
     * @param {Message[]} messageList
     */
    handleCloseGroupLive(messageList) {
      messageList.forEach((message) => {
        if (
          this.currentConversation.conversationID === message.conversationID &&
          message.type === this.TIM.TYPES.MSG_CUSTOM
        ) {
          let data = {};
          try {
            data = JSON.parse(message.payload.data);
          } catch (e) {
            data = {};
          }
          if (data.roomId && Number(data.roomStatus) === 0) {
            this.$bus.$emit('close-group-live');
          }
        }
      });
    },
    // 关闭页面调用方法
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },
    async unloadHandler() {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      if (this._gap_time <= 5) {
        // cosole.log('关闭')
        // $.ajax({
        //   url: '/pictureweb/user/remove',
        //   type: 'get',
        //   async:false, //或false,是否异步
        // })
        const params = {
          userId: this.userID,
        };
        await handlestopReceivingOrders(params);
      }
    },

    // 根据GROUPid 去调接口 获取对应的学生信息 |  对比前后 event.data GROUPid 是否一样issame 不一样去调学生信息接口 (如果有筛选条件 也需要调学生信息接口)
    handleGetStudentInfo(issame) {
      // 现有数据的 groupIds
      if (this.userID) {
        console.log('this.userID2', !issame || this.studentName);
        if (!issame || this.studentName) {
          const params = {
            serverId: this.userID,
            studentName: this.studentName,
          };
          //每次新的聊天数据 都会去请求学生信息接口 会导致接口太频繁 -》判断IM前后的群id是否一样 不一样才去调用学生信息的接口数据
          getStudentsInfo(params).then((res) => {
            if (res.status === 200) {
              // const studentList = res.data.content.list
              const studentList = res.data;
              this.studentsInfo = studentList;
              this.$store.state.conversation.totalCount = res.data.length;
              let list = [];
              if (studentList.length === 0) {
                list = [];
              } else {
                //根据groupID去
                list = getArrEqualXWK(studentList, this.sdkStudentList);
              }
              // console.log('接口 studentList',studentList)
              console.log('接口 list', list);
              this.$store.commit('updateConversationList', list);
            }
          });
        } else {
          // console.log('本地 this.studentsInfo',this.studentsInfo)
          // console.log('本地 this.sdkStudentList',this.sdkStudentList)
          const list = getArrEqualXWK(this.studentsInfo, this.sdkStudentList);
          console.log('本地 list', list);
          this.$store.commit('updateConversationList', list);
        }
      }
    },
    handleStudentInfoSearch(type) {
      //  console.log('search',type)
      if (!this.userID) {
        return;
      }
      this.$store.commit('setSearchLoading', true);
      const params = {
        serverId: this.userID,
        studentName: this.searchStudentName,
      };
      // console.log('handleStudentInfoSearch-params',params)
      getStudentsInfo(params)
        .then((res) => {
          if (res.status === 200) {
            // 更新学生信息
            const studentList = res.data;
            this.studentsInfo = studentList;
            if (studentList.length === 0) {
              // 不用提醒 本身下面就会有 筛选结果条数；其次清楚筛选 如果结果返回为空 也会提醒没有学生信息
              // this.$store.commit('showMessage', {
              //   message: '没有找到该学生',
              //   type: 'warning'
              // })
              this.$store.commit('updateConversationList', []);
            } else {
              //根据name滤掉
              this.sdkStudentList = session.get('sdkStudentList');
              const list = getArrEqualXWK(studentList, this.sdkStudentList);
              // console.log('list',list)
              this.$store.commit('updateConversationList', list);
            }
          }
          this.$store.commit('setSearchLoading', false);
        })
        .catch((err) => {
          this.$store.commit('setSearchLoading', false);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>



.container
  position relative
  .chat-botWrap{
    margin-top: 20px
    background-color :#fff
    border-radius:4px
    .student-infoWrap{
      margin-left:20px
    }
  }
.container
  position relative
// TODO filter mac chrome 会有问题，下次修改可以去掉
.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url('~@/./assets/IM/image/bg.jpg') no-repeat 0 0;
  background-size: cover;
  // filter blur(67px)
}

.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-botWrap{
  display:flex
  // padding:20px
  padding: 20px 20px 20px 0;
}
.chat-wrapper {
  width: $width;
  height: $height;
  max-width: 1280px;
  // box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.3);

  .official-link {
    display: flex;
    text-decoration: none;
    color: #38c9ff;
    width: fit-content;
    float: right;
    height: 45px;
    align-items: center;
  }
}
</style>
